import React, { useState } from 'react';
import { View, TextInput } from 'react-native';

import { useSizeState } from './Size';

const Input = ({ value, onChange }) => {
  const [dimension] = useSizeState();
  return (
    <View>
      <TextInput
        onChangeText={onChange}
        value={value}
        dim={dimension}
        css={`
          align-self: center;
          color: ${props => props.theme.colors.text};
          font-family: nunito;
          font-size: ${props => props.theme.fontSizes[2]}px;
          margin: ${props => props.theme.space[3]}px;
          padding: ${props => props.theme.space[2]}px;
          min-width: ${props => props.theme.space[7]}px;
          border: ${props =>
              `${props.theme.colors.blue['700']} ${props.theme.space[1]}px`}
            solid;
          border-radius: ${props => props.theme.space[3]}px;
          ${({ dim, theme }) =>
            dim.m &&
            `
              font-size: ${theme.fontSizes[3]}px;
            `}
          ${({ dim, theme }) =>
            dim.l &&
            `
              align-self: flex-start;
              margin-left: ${theme.space[6]}px;
            `}
        `}
      />
    </View>
  );
};

const ObjectSearch = () => {
  const [value, setValue] = useState('');

  return <Input value={value} onChange={setValue} />;
};

export default ObjectSearch;
