import React, { useState, useEffect } from 'react';
import { ScrollView } from 'react-native';
import { ApolloProvider } from '@apollo/react-hooks';

// import { useAuthState } from './auth/authWrapper';
import makeApolloClient from './apollo';
import Header from './Header';
import ObjectSearch from './ObjectSearch';
import Card from './Card';
// import { AuthProvider } from './auth/authWrapper';
// import Unauthenticated from './Unauthenticated';
// import Authenticated from './Authenticated';

const Home = () => {
  // const [auth, isAuthenticated] = useAuthState();
  const auth = { token: undefined };
  const [client, setClient] = useState(null);

  useEffect(() => {
    // create a client with token, when authenticated
    // if (auth.token) { // we're ok with anonym access
    const createClient = async () => {
      const newClient = await makeApolloClient(auth.token);
      setClient(newClient);
    };
    createClient();
    // }
  }, [auth.token]);

  return (
    <>
      <Header />
      {client && (
        <ApolloProvider client={client}>
          <ScrollView>
            <ObjectSearch />
            <Card />
          </ScrollView>
        </ApolloProvider>
      )}
    </>
  );
  // return (
  //   <>
  //     {!isAuthenticated && <Unauthenticated />}
  //     {isAuthenticated && client && (
  //       <ApolloProvider client={client}>
  //         <Authenticated />
  //       </ApolloProvider>
  //     )}
  //   </>
  // );
};

// export default props => (
//   <AuthProvider>
//     <Home {...props}></Home>
//   </AuthProvider>
// );

export default Home;
