import React from 'react';
import { View } from 'react-native';

import Text from './Text';
import { useSizeState } from './Size';

const Header = () => {
  const [dimension] = useSizeState();

  return (
    <>
      <View
        css={`
          padding: ${props => props.theme.space[3]}px 0;
          background-color: ${props => props.theme.colors.white};
        `}
      >
        <Text
          accessibilityRole="header"
          dim={dimension}
          css={`
            align-self: center;
            color: ${props => props.theme.colors.black};
            font-family: nunito-bold;
            font-size: ${props => props.theme.fontSizes[6]}px;
            font-weight: 700;
            ${({ dim, theme }) =>
              dim.m &&
              `
              font-size: ${theme.fontSizes[7]}px;
            `}
            ${({ dim, theme }) =>
              dim.l &&
              `
              align-self: flex-start;
              margin-left: ${theme.space[6]}px;
            `}
          `}
        >
          Savior
        </Text>
      </View>
    </>
  );
};

export default Header;
