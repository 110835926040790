import React, { useEffect, useState } from 'react';
import { SafeAreaView, StatusBar, Text } from 'react-native';
import * as Font from 'expo-font';
import { ThemeProvider } from 'styled-components';
import {
  configureFonts,
  DefaultTheme,
  Provider as PaperProvider,
} from 'react-native-paper';

import { SizeProvider } from './src/Size';
import theme from './src/theme';
import Home from './src/Home';

const fontConfig = {
  default: {
    regular: {
      fontFamily: 'nunito',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'nunito',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'nunito',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'nunito',
      fontWeight: 'normal',
    },
  },
};

const paperTheme = {
  ...DefaultTheme,
  fonts: configureFonts(fontConfig),
};

const App = () => {
  // const [fontLoaded, setFontLoaded] = useState(true);
  useEffect(() => {
    const loadFonts = async () => {
      await Font.loadAsync({
        nunito: require('./assets/fonts/Nunito-Regular.ttf'),
        'nunito-bold': require('./assets/fonts/Nunito-Bold.ttf'),
      });
    };
    loadFonts();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <PaperProvider theme={paperTheme}>
        <SizeProvider>
          <SafeAreaView
            css={`
              flex: 1;
              flex-direction: column;
              justify-content: flex-start;
              align-self: stretch;
              align-items: stretch;
              background-color: ${props => props.theme.colors.background};
            `}
          >
            <StatusBar
              backgroundColor="#1a202c"
              barStyle="light-content"
              translucent={false}
            />
            <Home />
          </SafeAreaView>
        </SizeProvider>
      </PaperProvider>
    </ThemeProvider>
  );
};

export default App;
